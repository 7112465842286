import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface LogoProps {
    width?: string;
}

function Logo({ width }: LogoProps) {
    return (
        <Link component={RouterLink} to="/home">
            <img alt="Crawlo logo" src={require(`assets/logo.png`)} width={width} />
        </Link>
    );
}

export default Logo;
