import axios from 'axios';

const server = 'https://api.gtinauto.crawlo.com';

class AuthService {
    private instance = axios.create({
        baseURL: server,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        // other custom settings
        timeout: 1000 * 60 * 60
    });
    AuthService() {
        this.instance = axios.create({
            baseURL: server,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            // other custom settings
            timeout: 1000 * 60 * 60
        });
    }
    async userLogin(email: string, password: string) {
        const response = await this.instance
            .post(`/auth/login`, {
                email,
                password
            })
            .then((res) => {
                return { data: res.data, error: null };
            })
            .catch((err) => {
                return { error: err.message, data: null };
            });

        if (!response) throw new Error('Somthing went wrong');

        return response;
    }

    async userSignUp(name: string, email: string, password: string) {
        const response = await this.instance
            .post(`/auth/register`, {
                name,
                email,
                password
            })
            .then((res) => {
                return { data: res.data, error: null };
            })
            .catch((err) => {
                return { error: err.message, data: null };
            });

        if (!response) throw new Error('Somthing went wrong');

        return response;
    }

    async userProfile(token: string) {
        localStorage.setItem('token', token);
        const response = await this.instance
            .get(`/auth/profile`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                return { data: res.data, error: null };
            })
            .catch((err) => {
                return { error: err.message, data: null };
            });

        if (!response) throw new Error('Somthing went wrong');

        return response;
    }
}

export default AuthService;
